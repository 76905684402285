import _objectWithoutProperties from "@babel/runtime/helpers/esm/objectWithoutProperties";
import _extends from "@babel/runtime/helpers/esm/extends";
var _excluded = ["adUnit", "footerData", "config", "structure"];
import React from 'react';
import { Icon } from '@magalu/stereo-ui-icons';
import { slug } from '@magalu/mixer-utils';
import { AdsBanner } from '..';
var mapLoginProps = function mapLoginProps(_ref) {
  var _ref$header = _ref.header,
    loginLinkText = _ref$header.label,
    loginLinkURL = _ref$header.url,
    loginMessage = _ref$header.loginMessage,
    _ref$header$helpers = _ref$header.helpers,
    helpers = _ref$header$helpers === void 0 ? [] : _ref$header$helpers,
    name = _ref.accountData.name;
  return {
    isSignedIn: !!name,
    signedConfig: {
      menuLinks: helpers.map(function (helper) {
        return {
          icon: React.createElement(Icon, {
            name: helper.id
          }),
          label: helper.label,
          url: helper.url
        };
      }),
      username: name ? name.split(' ')[0] : ''
    },
    unsignedConfig: {
      loginLink: {
        color: 'white',
        text: loginLinkText,
        url: loginLinkURL
      },
      message: {
        color: 'white',
        text: loginMessage
      }
    }
  };
};
var mapMenuFooterDataProps = function mapMenuFooterDataProps(menuFooterLinks) {
  return {
    linksList: menuFooterLinks.map(function (link) {
      return _extends({
        label: link.label,
        url: link.url
      }, Icon && {
        img: {
          component: React.createElement(Icon, {
            name: link.id
          })
        }
      });
    })
  };
};
var mapLinkMenu = function mapLinkMenu(_ref2) {
  var adUnit = _ref2.adUnit,
    footerData = _ref2.footerData,
    config = _ref2.config,
    structure = _ref2.structure,
    props = _objectWithoutProperties(_ref2, _excluded);
  return _extends({}, props, adUnit && {
    banner: {
      component: React.createElement(AdsBanner, {
        structure: structure,
        "static": adUnit["static"]
      }),
      width: adUnit["static"].size[0]
    }
  }, footerData && {
    footerData: mapMenuFooterDataProps(footerData.linksList)
  });
};
var mapCategoryList = function mapCategoryList(categoryList, config, structure) {
  var mappedMenuList = Array.isArray(categoryList == null ? void 0 : categoryList.list) ? categoryList == null ? void 0 : categoryList.list.map(function (menu) {
    return mapLinkMenu(_extends({}, menu, {
      config: config,
      structure: structure
    }));
  }) : [];
  return _extends({}, categoryList, {
    list: mappedMenuList
  });
};
var mapZipCodeProps = function mapZipCodeProps(_ref3) {
  var zipCode = _ref3.zipCode,
    zipCodeBar = _ref3.zipCodeBar,
    zipCodeCity = _ref3.zipCodeCity,
    zipCodeState = _ref3.zipCodeState;
  var zipCodeBarText = zipCodeBar.zipCodeBarText,
    showZipcode = zipCodeBar.showZipcode,
    popper = zipCodeBar.popper;
  return _extends({}, zipCodeBarText, {
    city: zipCodeCity,
    popper: popper,
    showZipcode: showZipcode,
    state: zipCodeState,
    zipcode: zipCode
  });
};

export default (function (_ref4) {
  var _staticProps$menuData, _staticProps$menuData2, _staticProps$menuData3, _data$allCategories, _structure$theme;
  var data = _ref4.data,
    staticProps = _ref4.staticProps,
    structure = _ref4.structure,
    config = _ref4.config,
    location = _ref4.location;
  var store = staticProps == null ? void 0 : (_staticProps$menuData = staticProps.menuData) == null ? void 0 : (_staticProps$menuData2 = _staticProps$menuData.data) == null ? void 0 : (_staticProps$menuData3 = _staticProps$menuData2.allCategories) == null ? void 0 : _staticProps$menuData3.store;
  var subcategoriesList = ((data == null ? void 0 : (_data$allCategories = data.allCategories) == null ? void 0 : _data$allCategories.map(function (_ref5) {
    var id = _ref5.id,
      label = _ref5.label,
      description = _ref5.description;
    return {
      id: id,
      label: label,
      url: "".concat(structure.config.domain).concat(store || '', "/").concat(slug(description), "/l/").concat(slug(id), "/")
    };
  })) || []).sort(function (a, b) {
    return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
  });
  var headerData = staticProps.headerData,
    _staticProps$menuData4 = staticProps.menuData;
  _staticProps$menuData4 = _staticProps$menuData4 === void 0 ? {} : _staticProps$menuData4;
  var _staticProps$menuData5 = _staticProps$menuData4.data;
  _staticProps$menuData5 = _staticProps$menuData5 === void 0 ? {} : _staticProps$menuData5;
  var _staticProps$menuData6 = _staticProps$menuData5.account,
    account = _staticProps$menuData6 === void 0 ? {} : _staticProps$menuData6,
    _staticProps$menuData7 = _staticProps$menuData5.companyLinks,
    companyLinks = _staticProps$menuData7 === void 0 ? {} : _staticProps$menuData7,
    _staticProps$menuData8 = _staticProps$menuData5.contactLinks,
    contactLinks = _staticProps$menuData8 === void 0 ? {} : _staticProps$menuData8,
    _staticProps$menuData9 = _staticProps$menuData5.specialLinks,
    specialLinks = _staticProps$menuData9 === void 0 ? [] : _staticProps$menuData9,
    _staticProps$menuData10 = _staticProps$menuData5.allCategories,
    allCategories = _staticProps$menuData10 === void 0 ? {} : _staticProps$menuData10,
    _staticProps$menuData11 = _staticProps$menuData5.categoryList,
    categoryList = _staticProps$menuData11 === void 0 ? {} : _staticProps$menuData11,
    _staticProps$menuData12 = _staticProps$menuData5.externalLinks,
    externalLinks = _staticProps$menuData12 === void 0 ? [] : _staticProps$menuData12,
    _staticProps$menuData13 = _staticProps$menuData5.header,
    header = _staticProps$menuData13 === void 0 ? {} : _staticProps$menuData13,
    _staticProps$zipCodeB = staticProps.zipCodeBar,
    zipCodeBar = _staticProps$zipCodeB === void 0 ? {} : _staticProps$zipCodeB;
  var _structure$cookies = structure.cookies;
  _structure$cookies = _structure$cookies === void 0 ? {} : _structure$cookies;
  var _structure$cookies$ac = _structure$cookies.accountData,
    accountData = _structure$cookies$ac === void 0 ? {} : _structure$cookies$ac,
    _structure$cookies$re = _structure$cookies.rejectZipcodePopup,
    rejectZipcodePopup = _structure$cookies$re === void 0 ? false : _structure$cookies$re,
    _structure$route = structure.route;
  _structure$route = _structure$route === void 0 ? {} : _structure$route;
  var storeId = _structure$route.storeId;
  var zipCode = location.zipCode,
    zipCodeCity = location.zipCodeCity,
    zipCodeState = location.zipCodeState;
  var logoLuPath = (structure == null ? void 0 : (_structure$theme = structure.theme) == null ? void 0 : _structure$theme.name) || 'magazineluiza';
  return {
    data: {
      account: account,
      allCategories: mapLinkMenu(_extends({}, allCategories, {
        config: config,
        structure: structure,
        subcategoriesList: subcategoriesList
      })),
      categoryList: mapCategoryList(categoryList, config, structure),
      companyLinks: companyLinks,
      contactLinks: contactLinks,
      externalLinks: externalLinks.map(function (externalLink) {
        return mapLinkMenu(_extends({}, externalLink, {
          config: config,
          structure: structure
        }));
      }),
      headerData: headerData,
      login: mapLoginProps({
        accountData: accountData,
        header: header
      }),
      specialLinks: specialLinks.map(function (specialLink) {
        return mapLinkMenu(_extends({}, specialLink, {
          config: config,
          structure: structure
        }));
      }),
      zipcode: mapZipCodeProps({
        zipCode: zipCode,
        zipCodeBar: zipCodeBar,
        zipCodeCity: zipCodeCity,
        zipCodeState: zipCodeState
      })
    },
    initialZipcodePopper: !zipCode && !rejectZipcodePopup ? 'info' : null,
    logoLuProps: {
      height: '113px',
      href: "https://wx.mlcdn.com.br/site/desk/header/".concat(logoLuPath, "/logo-lu.png?ims=69x113"),
      name: 'LogoMagalu',
      titleaccess: 'Logo Lu',
      viewBox: '0 0 69 113',
      width: '69px',
      y: '4'
    },
    logoProps: staticProps.logoProps || {
      storeName: storeId
    }
  };
});