import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import React, { useEffect } from 'react';
import { shape } from 'prop-types';
import StereoLuContent from '@magalu/stereo-ui/templates/LuContent';
import { usePublisher } from '@magalu/mixer-publisher';
import { parseBlogLuContent } from '@magalu/mixer-utils';
import withLayoutProps from '../../../hocs/withLayoutProps';
import { Container } from './LuContent.styles';
var LuContent = function LuContent(_ref) {
  var data = _ref.data,
    staticProps = _ref["static"];
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var variation = staticProps.variation,
    title = staticProps.title,
    showHeaderSocialMedia = staticProps.showHeaderSocialMedia,
    showLinkToMoreContent = staticProps.showLinkToMoreContent,
    linkToMoreContent = staticProps.linkToMoreContent,
    _staticProps$assets = staticProps.assets;
  _staticProps$assets = _staticProps$assets === void 0 ? {} : _staticProps$assets;
  var images = _staticProps$assets.images;
  var _data$blogHomeContent = data.blogHomeContent,
    blogHomeContent = _data$blogHomeContent === void 0 ? {} : _data$blogHomeContent,
    _data$blogPostsListin = data.blogPostsListing,
    blogPostsListing = _data$blogPostsListin === void 0 ? {} : _data$blogPostsListin,
    _data$blogPostsByEcom = data.blogPostsByEcomm,
    blogPostsByEcomm = _data$blogPostsByEcom === void 0 ? {} : _data$blogPostsByEcom;
  var _ref2 = blogHomeContent || {},
    _ref2$recommendations = _ref2.recommendations,
    blogRecommendations = _ref2$recommendations === void 0 ? [] : _ref2$recommendations;
  var blogLuContent = [].concat(_toConsumableArray(blogRecommendations), [blogPostsListing, blogPostsByEcomm]).filter(function (item) {
    return item == null ? void 0 : item.posts;
  });
  var parsedBlogLuContent = parseBlogLuContent(blogLuContent, variation);
  var allBlogItems = blogLuContent.reduce(function (acc, _ref3) {
    var posts = _ref3.posts;
    return [].concat(_toConsumableArray(acc), _toConsumableArray(posts));
  }, []).filter(Boolean);
  useEffect(function () {
    var promotionData = allBlogItems.map(function (_ref4, index) {
      var id = _ref4.id,
        articleTitle = _ref4.title,
        category = _ref4.category,
        subcategory = _ref4.subcategory;
      return {
        category: category,
        id: id,
        position: index + 1,
        subcategory: subcategory,
        title: articleTitle
      };
    });
    publish('lucontent:view', {
      eventLabel: 'promotion',
      promotions: promotionData
    });
  }, [data]);
  var handleClick = function handleClick(e, clicked) {
    var id = clicked.id,
      itemTitle = clicked.title,
      idx = clicked.idx,
      parentIdx = clicked.parentIdx;
    var position = parentIdx + 1 + idx + 1;
    publish('lucontent:click', {
      eventLabel: 'promotion',
      id: id,
      position: position,
      title: itemTitle
    });
  };
  var handleSocialMediaLinkClick = function handleSocialMediaLinkClick(_e, _ref5) {
    var id = _ref5.id;
    publish('lucontent:socialmedia:click', {
      id: id
    });
  };
  var luContentProps = {
    assets: staticProps.assets,
    data: parsedBlogLuContent,
    images: images,
    linkToMoreContent: linkToMoreContent,
    onItemClick: handleClick,
    onSocialMediaClick: handleSocialMediaLinkClick,
    showHeaderSocialMedia: showHeaderSocialMedia,
    showLinkToMoreContent: showLinkToMoreContent,
    title: title,
    variation: variation
  };
  if (allBlogItems.length === 0) {
    return null;
  }
  return React.createElement(Container, {
    "data-testid": "lucontent-container"
  }, React.createElement(StereoLuContent, luContentProps));
};
LuContent.defaultProps = {
  data: {},
  "static": {},
  structure: {}
};
process.env.NODE_ENV !== "production" ? LuContent.propTypes = {
  data: shape({}),
  "static": shape({}),
  structure: shape({})
} : void 0;
LuContent.ssr = true;
export default withLayoutProps(LuContent);